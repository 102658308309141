html{
  color: white;
}

.site-hero {
  background-image: url(./assets/me-gray.png);
}

.pt-7 {
  padding-top: 20rem;
}

@media (min-width: 1078px) {
  .resume-container {
    width: 95%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px
    
  }
  .resume-item {
    display: grid;
    grid-template-rows: 1fr 45px;
    height: 240px
  }
}


@media only screen and (max-width: 1077px) {
  .resume-container {
    width: 90%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px
    
  }
  .resume-item {
    display: grid;
    grid-template-rows: 1fr 45px;
    height: 270px
  }
}

@media only screen and (max-width:480px) {
  .resume-container {
    width: 90vw;
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px
    
  }
  .resume-item {
    display: grid;
    grid-template-rows: 1fr 45px;
    height: 180px
  }

}

.resume-item h3 {
  padding-top: 7px;
}

.skill-logo {
  margin: auto;
  width: 70%;
  text-align: center;
}

.btn-primary {
  color: #fff;
  background-color: #bac964;
  border-color: #bac964;
}

.site-form textarea:focus, .site-form input:focus {
  color: white;
}

.site-navbar.bg-light {
  top: 0px;
}

@media (min-width: 993px) {
  .nav-container {
    background: #59585875;
  }
}

/* <<<<<<<<< SLIDER STYLING >>>>>>>>>>>>>>>> */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 14px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 6px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #353536;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 20%;
}